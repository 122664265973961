<svelte:options tag="fds-i18n-input-text"></svelte:options>

<script>
    import { tick, onMount, beforeUpdate,afterUpdate } from 'svelte'
    import { get_current_component } from 'svelte/internal';
    import { createEventDispatcher } from "svelte";
    import CodeTranslator from "./CodeTranslator";
    //codeTranslator.translateIsoToShort(lng[i]);
    import fdsHelper from "@fds-components/fds-helper"
    import {name as pname, version as componentversion} from '../package.json';
    const path = fdsHelper.get_href()
    /**
     * The name of the theme
     * @type {string}
     */
    export let theme=""
    /**
     * The status defined display of element default  normal
     * @type {normal|error|warning|success}
     */
    export let status=""
    /**
     * predefined size of element default - normal size also default one,lg - large sizesm - small size
     * @type {default|lg|sm}
     */
    export let size="";

    /**
     * The text field width in CSS value (e.g. 200px), default: 100%
     * @type {string}
     */
    export let width=""

    /**
     *  HTML input placeholder
     * @type {string}
     */
    export let placeholder=""
    /**
     * If true the value of input will be shown without width limitation
     * @type {false | true }
     */
    export let readonly=false
    /**
     * Set input to grey disabled state and deactivates editing
     * @type {false | true }
     */
    export let disabled=false

    /**
     * list of available languages like: "en_EN,de_DE";
     * @type {string}
     */
    export let iso_langlist="en_EN,de_DE";
    /**
     * selected language from iso_langlist
     * @type {string}
     */
    export let selected_language = "en_EN";
    /**
     * get the value as an object
     * @type {{}}
     */
    export let  value = {};
    /**
     * get or set the value as string like:'{"en_EN":"eng text","de_DE":"de text"}'
     * @type {string}
     */
    export let  valuestring="";



    let name=""
    let icon_append=""
    let iso_langlist_arr = [];
    let selected_language_short = "gb";
    let host = get_current_component()
    let contextMenuData = {};
    let svelteDispatch = createEventDispatcher();
    const dispatch = (name, detail) => {
        svelteDispatch(name, detail)
        host.dispatchEvent && host.dispatchEvent(new CustomEvent(name, { detail }))
    }
    let error=""
    let ref=null;
    let allinputprops = {};
    /**
     * input element container
     * @type {HTMLElement}
     */
    export let inputel;
    let inputellisteneradded = false;
    let codeTranslator;


    $:{
        if (codeTranslator) selected_language_short = codeTranslator.translateIsoToShort(selected_language);
        allinputprops = {status,readonly,theme,width,size,placeholder,disabled,icon_append:`flags ${selected_language_short}`,iconclick:true};
        if (valuestring && !isJsonLike(valuestring)) addAsFirstValue();
        if (valuestring && isJsonLike(valuestring) && valuestring!=JSON.stringify(value)) {value = JSON.parse(valuestring);}
        if(value && isJsonLike(valuestring) && inputel && selected_language && value[selected_language]!=inputel.value) {
            inputChange({detail:{value:value[selected_language]}});
        }
    }

    /**
     * Get information about component
     * @param  {("api" | "examples" | "css")} type the info type
     */
    export async function getInfo(type) {
        if (type==="version"){
            return new Promise(resolve => {
                resolve(componentversion);
            });
        }
        let res = await fdsHelper.getInfo(type,pname);
        return res;
    }

    /**
     * version of component
     * @type {string}
     */
    export const version = componentversion;

    afterUpdate(()=> {
        if(inputel && !inputellisteneradded){
            inputel.addEventListener('iconClick', (el) => {
                //console.log("clicked!!!");
                createContextMenu(el);
            });
            inputel.addEventListener('change',inputChange);
            inputellisteneradded = true;
        }
    });

    beforeUpdate(()=> {})

    onMount(()=> {
        createContextMenuData();
    });

    function  addAsFirstValue(){
        let firstlang = iso_langlist_arr[0];
        if(value && !value[firstlang]) value[firstlang] = valuestring;
        valuestring = JSON.stringify(value);
    }

    function isJsonLike(str) {
        if (/^[\],:{}\s]*$/.test(str.replace(/\\["\\\/bfnrtu]/g, '@').
        replace(/"[^"\\\n\r]*"|true|false|null|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?/g, ']').
        replace(/(?:^|:|,)(?:\s*\[)+/g, ''))) {
            return true;
        }else{
            return false;
        }
    }

    /**
     * Will be fired if Language was changed
     * @event selectLanguage
     */

    let langChange = (id,val,entry) => {
        //console.log("lang change!!",id,val,entry);
        selected_language = codeTranslator.translateShortToIso(entry.id);
        inputel.value = value[selected_language] || "";
        dispatch("selectLanguage",{selected_language});
    }

    function createContextMenuData(){
        codeTranslator = new CodeTranslator();
        iso_langlist_arr = iso_langlist.split(",");
        iso_langlist_arr.forEach((el)=>{
            let short = codeTranslator.translateIsoToShort(el);
            let dname = el.split('_')[1];
            contextMenuData[short] = {icon: `flags ${short}`,name:dname}
        });

    }


    function createContextMenu(el){
        let contextMenu=document.createElement("fds-context-menu")
        contextMenu.element=inputel.shadowRoot.querySelector('fds-icon');
        contextMenu.menu=contextMenuData;
        contextMenu.callback=langChange
        if(document.body) document.body.append(contextMenu);
    }

    /**
     * Change input event handler
     * @event change
     */

    function inputChange(ev) {
        //console.log("input change");
        let detail = ev.detail;
         value[selected_language] =  detail.value;

        iso_langlist_arr.forEach((el)=>{if(!value[el]) value[el]="";});

        valuestring = JSON.stringify(value);
        inputel.value = ev.detail.value || "";
        dispatch("change",{value:detail.value})
    }
</script>



<span class="container">
    <fds-input-text bind:this={inputel} {...allinputprops}></fds-input-text>
</span>


<style>
    * {
        box-sizing: border-box;
    }
.container {
    position: relative;
    height: var(--height);
    display:block;
    overflow:var(--overflow);

}
</style>
